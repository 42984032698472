import * as Sentry from '@sentry/browser'
import { isProd } from '@centrito/app/config'

const client = new Sentry.BrowserClient({
  dsn: 'https://85661895823dc9219195ed4758df2e46@o1168684.ingest.us.sentry.io/4506758222053376',
  transport: Sentry.makeFetchTransport,
  stackParser: Sentry.defaultStackParser,
  integrations: [
    Sentry.breadcrumbsIntegration(),
    Sentry.globalHandlersIntegration(),
    Sentry.linkedErrorsIntegration(),
    Sentry.dedupeIntegration(),
  ],
  normalizeDepth: 10,
  enabled: isProd,
})

Sentry.getCurrentScope().setClient(client)
client.init()

export default client
